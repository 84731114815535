<template>
    <div v-if="setup">
        <div class="chooseEventType">
            <div class="chooseEventTypeTag">
                <p style="font-size: 24px; font-weight: bold">会议入口</p>
                <div>CONFERENCE ENTRANCE</div>
            </div>
            <img :src="backgroundImg" class="chooseEventTypeImg" />
        </div>
        <div class="cnt">
            <template v-for="(item, index) of options.options">
                <div class="cnt-item" :key="index" @click="onSelectEventType(item)">
                    <div class="left-icon">
                        <i-icon :size="item.iconSize" :color="item.iconColor" :name="item.icon" />
                    </div>
                    <div class="right-box">
                        <div class="title">{{ item.eventTypeName }}</div>
                        <div class="desc">{{ item.eventTypeTitle }}</div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import * as tinyCookie from "tiny-cookie";
import { httpService } from "../services/httpService";
export default {
    name: "chooseEventType",
    data() {
        return {
            options: {},
            backgroundImg: '',
            setup: false
        };
    },
    async beforeRouteEnter(to, from, next) {
        const params = {
            collection: "cfg-ievent-nav",
            filter: {
                tenantCode: tinyCookie.get("tenant"),
                tenantId: tinyCookie.get("tenant_id"),
            },
        };
        httpService.GetSettings(params).then((response) => {
            if (response?.content?.options.find((x) => x.txt == "首页")) {
                next();
            } else {
                next(vm => {
                    vm.$smartStorage.remove('homeQueryListExpr')
                    if(response?.content?.options[0]?.homeQueryExpr) {
                        vm.$smartStorage.set("homeQueryListExpr",response?.content?.options[0]?.homeQueryExpr)
                    }
                    vm.$router.push("/eventList")
                })
            }
        });
    },
    async created() {
        this.$smartStorage.remove("homeQueryListExpr");

        let params = {
            collection: "cfg-ievent-mice-category-operation",
            filter: {
                tenantCode: this.$cookie.get("tenant") || this.$smartStorage.get("tenant"),
                tenantId: this.$cookie.get("tenant_id") || "",
            },
        };

        let res = await this.$service.GetSettings(params);
        if (res && res.success && res.content) {
            this.setup = true
            console.log("====首页的按钮配置", res.content);
            this.options = res.content;
            this.backgroundImg = res.content.backgroundImg || ''
            // 保存会议类型tab
            res.content.options.find((x) => {
                this.$smartStorage.set(x.eventTypeCode + "EventTabs", x.eventStatus);
            });
        }
    },
    methods: {
        onSelectEventType(item) {
            //通过store传递一些参数
            //测试提了刷新的bug，转存至session
            this.$smartStorage.set("homeQueryListExpr", { listExpr: item.queryListExpr, cardExpr: item.queryListCard });
            this.$smartStorage.set("currentEventTypeTab", 0);
            this.$smartStorage.set("selectedEventType", item.eventTypeCode);
            this.$router.push({
                path: "/eventList?eventType=" + item.eventTypeCode,
            });
        },
    },
};
</script>

<style lang="less" scoped>
.chooseEventType {
    position: relative;
    height: 2.76rem;
    .chooseEventTypeTag {
        position: absolute;
        left: 0.27rem;
        top: 0.57rem;
        p {
            color: #14161c;
            text-align: left;
            font-size: 0.17rem;
        }
        div {
            color: #90949a;
            text-align: left;
            font-size: 0.1rem;
        }
    }
    .chooseEventTypeImg {
        height: 2.76rem;
        width: 100%;
        display: block;
    }
}
.cnt {
    padding: 0 0.1rem;
    position: absolute;
    top: 2rem;
    width: 100vw;
    box-sizing: border-box;
    &-item {
        width: 100%;
        padding: 0.18rem 0.25rem;
        box-sizing: border-box;
        background-color: #FFFFFF;
        box-shadow: 0px 0.02rem 0.08rem 0px rgba(0,0,0,0.1);
        border-radius: 0.04rem;
        display: flex;
        align-items: center;
        margin-bottom: 0.08rem;
        .left-icon {
            width: 0.27rem;
            height: 0.27rem;
        }
        .right-box {
            margin-left: 0.23rem;
            .title {
                font-size: 0.16rem;
                font-weight: 500;
                color: #13161A;
            }
            .desc {
                font-size: 0.14rem;
                font-weight: 500;
                color: #90939A;
            }
        }
    }
}
</style>
